/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from "prop-types";
import { IconArrowLeft, IconX } from "@tabler/icons";
import ImageFallbackWithNext from "Components/image-fallback-next";

const WithOverlay = ({
	show,
	title,
	children,
	zIndex,
	contentBackground,
	onHide,
	onBack,
	headerIconSrc,
	titleFontSize,
	titlePadding,
}) => (
	<>
		<div className={`drawer-withOverlay-filter ${show ? "show" : ""}`} onClick={onHide} />
		<div className={`drawer-mobile-withOverlay ${show ? "show" : ""}`}>
			<div className="head">
				<div className="navigation">
					{
						typeof onBack === "function" && (
							<span className="back">
								<IconArrowLeft stroke="1" onClick={onBack} />
							</span>
						)
					}
					{headerIconSrc && (
						<span style={{
							position: "relative",
							height: "32px",
							width: "56px",
						}}
						>

							<ImageFallbackWithNext src={headerIconSrc} />
						</span>
					)}
					<span className="title">{title}</span>
				</div>
				<div className="action">
					{
						typeof onHide === "function" && (
							<span>
								<IconX stroke="1.5" onClick={onHide} />
							</span>
						)
					}
				</div>
			</div>
			<div className="content">
				{children}
			</div>
		</div>
		<style>
			{`
				.drawer-withOverlay-filter {
					position: fixed;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					background: #000;
					z-index: ${zIndex - 1};
					opacity: 0;
					visibility: hidden;
					transition: all .4s ease;
				}
				.drawer-withOverlay-filter.show {
					opacity: 0.5;
					visibility: visible;
				}
				.drawer-mobile-withOverlay {
					position: fixed;
					bottom: 0;
					left: 0;
					right: 0;
					bottom: -100%;
					top: 0;
					background: ${contentBackground};
					width: 0;
					z-index: ${zIndex};
					opacity: 0;
					visibility: hidden;
					transition: all .3s ease;
					margin: auto;
					height: max-content;
				}
				.drawer-mobile-withOverlay.show {
					bottom: 0;
					opacity: 1;
					visibility: visible;
				}
			`}
		</style>
		<style jsx>
			{`
				.drawer-mobile-withOverlay .head {
					display: flex;
					justify-content: space-between;
					align-items: center;
					height: auto;
					padding: ${titlePadding};
					background: #fff;
					border-bottom: 1px solid #DFE3E8;
				}
				.drawer-mobile-withOverlay .head .navigation {
					display: flex;
					align-items: center;
				}
				.drawer-mobile-withOverlay .head .navigation span:not(:first-child) {
					margin: 0 0 0 10px;
				}
				.drawer-mobile-withOverlay .head .navigation span.title {
					font-weight: 700;
					font-style: normal;
					font-size: ${titleFontSize};
					line-height: 16px;
					margin: 0 0 0 ${headerIconSrc ? "12px" : "0"};
				}
				.drawer-mobile-withOverlay .head .navigation span:first-child {
					cursor: pointer;
				}
				.drawer-mobile-withOverlay .content {
					overflow-y: scroll;
					box-sizing: border-box;
				}
				.drawer-mobile-withOverlay .content::-webkit-scrollbar {
					display: none;
				}
				
				@media screen and (max-width: 425px) {
          .drawer-mobile-withOverlay .head {
            padding: ${titlePadding};
          }
					.drawer-mobile-withOverlay.show {
						width: calc(100vw - 64px);
						margin: auto;
					}
				}
				@media screen and (width: 360px) and (height: 740px) {
					.drawer-mobile-withOverlay.show {
						width: calc(100vw - 64px);
					}
				}
				@media screen and (width: 412px) and (height: 915px) {
					.drawer-mobile-withOverlay.show {
						width: calc(100vw - 64px);
					}
				}
				@media screen and (min-width: 390px) and (max-height: 896px) {
					.drawer-mobile-withOverlay.show {
						width: calc(100vw - 64px);
					}
				}
				@media screen and (min-width: 768px) and (min-height: 1024px) {
					.drawer-mobile-withOverlay.show {
						width: calc(100vw - 240px);
						margin: auto;
					}
				}
				@media screen and (min-width: 1024px) and (min-height: 600px) {
					.drawer-mobile-withOverlay.show {
						width: calc(100vw - 240px);
					}
					.drawer-mobile-withOverlay .content {
						overflow-y: scroll;
						box-sizing: border-box;
					}
				}
			`}
		</style>
	</>
);

WithOverlay.propTypes = {
	show: PropTypes.bool,
	title: PropTypes.string.isRequired,
	children: PropTypes.any.isRequired,
	zIndex: PropTypes.number.isRequired,
	contentBackground: PropTypes.string.isRequired,
	onHide: PropTypes.func,
	onBack: PropTypes.func,
	headerIconSrc: PropTypes.string,
	titleFontSize: PropTypes.string,
	titlePadding: PropTypes.string,
};

WithOverlay.defaultProps = {
	show: false,
	headerIconSrc: null,
	titleFontSize: "16px",
	titlePadding: "16px",
};

export default WithOverlay;
