/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from "prop-types";
import { IconX } from "@tabler/icons";

const DrawerHalf = ({
	show, title, children, zIndex, contentBackground, onHide,
}) => (
	<>
		<div className={`drawer-half-filter ${show ? "show" : ""}`} onClick={onHide} />
		<div className={`drawer-half ${show ? "show" : ""}`}>
			<div className="head">
				<div className="navigation">
					<span className="title">{title}</span>
				</div>
				<div className="action">
					{
						typeof onHide === "function" && (
							<IconX stroke="1" onClick={onHide} />
						)
					}
				</div>
			</div>
			<div className="content">
				{children}
			</div>
		</div>
		<style>
			{`
				${show && `
					html, body {
						overflow: hidden;
						z-index: 1;
					}
				`}
			`}
		</style>
		<style jsx>
			{`
                .drawer-half-filter {
					position: fixed;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					background: #000;
					z-index: ${zIndex - 1};
					opacity: 0;
					visibility: hidden;
					transition: all .4s ease;
				}
				.drawer-half-filter.show {
					opacity: 0.5;
					visibility: visible;
				}
				.drawer-half {
					position: fixed;
					bottom: -40%;
					background: ${contentBackground};
					width: 480px;
					z-index: ${zIndex};
					opacity: 0;
					visibility: hidden;
					transition: all .3s ease;
					height: auto;
                    padding-top: 20px;
                    border-radius: 10px 10px 0px 0px;
				}
				.drawer-half.show {
					bottom: 0;
					opacity: 1;
					visibility: visible;
				}
				.drawer-half .head {
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: 16px 5px;
					background: #fff;
				}
				.drawer-half .head .navigation {
					display: flex;
					align-items: center;
					margin: 0 0 0 13px;
				}
				.drawer-half .head .navigation span:not(:first-child) {
					margin: 0 0 0 10px;
				}
				.drawer-half .head .navigation span.title {
					font-weight: 700;
					font-style: normal;
					font-size: 16px;
					line-height: 16px;
				}
				.drawer-half .head .navigation span:first-child {
					cursor: pointer;
				}
				.drawer-half .head .action {
					margin: 0 13px 0 0;
				}
				.drawer-half .content {
					height: 45vh;
					overflow-y: scroll;
					box-sizing: border-box;
                    padding-bottom: 80px;
					position: relative;
				}
				.drawer-half .content::-webkit-scrollbar {
					display: none;
				}
				.drawer-half .footer-btn {
					width: 375px;
					padding: 16px;
					background: #fff;
					box-shadow: 0px -3px 10px rgba(145, 158, 171, 0.1);
					position: fixed;
					bottom: 0;
					box-sizing: border-box;
					z-index: ${zIndex + 1};
				}
				
				@media screen and (max-width: 425px) {
					.drawer-half .show {
						bottom: 0;
						width: 100%;
					}
					.drawer-half {
						bottom: -40%;
						width: 100%;
					}
				}
			`}
		</style>
	</>
);

DrawerHalf.propTypes = {
	show: PropTypes.bool.isRequired,
	title: PropTypes.string.isRequired,
	children: PropTypes.any.isRequired,
	zIndex: PropTypes.number.isRequired,
	contentBackground: PropTypes.string.isRequired,
	onHide: PropTypes.func,
};

export default DrawerHalf;
