/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from "prop-types";
import Button from "@fdn/button";
import { IconX } from "@tabler/icons";

const Type2 = ({
	show, title, children, zIndex, contentBackground, onHide, onSave, saveTitle, isContentFlex,
}) => (
	<>
		<div className={`drawer-type-2-filter ${show ? "show" : ""}`} onClick={onHide} />
		<div className={`drawer-type-2 ${show ? "show" : ""}`}>
			<div className="head">
				<div className="navigation">
					<span className="title">{title}</span>
				</div>
				<div className="action">
					{
						typeof onHide === "function" && (
							<span>
								<IconX stroke="1.5" onClick={onHide} />
							</span>
						)
					}
				</div>
			</div>
			<div className={`content ${isContentFlex ? "flex" : ""}`}>
				{children}
			</div>
			{
				typeof onSave === "function" && (
					<div className="footer-btn">
						<Button block size="small" variant="primary-bs" font_family="Creato Display" font_weight="500" on_click={onSave}>{saveTitle}</Button>
					</div>
				)
			}
		</div>
		<style>
			{`
				${show && `
					html, body {
						overflow: hidden;
						z-index: 1;
					}
				`}
			`}
		</style>
		<style jsx>
			{`
				.drawer-type-2-filter {
					position: fixed;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					background: #000;
					z-index: ${zIndex - 1};
					opacity: 0;
					visibility: hidden;
					transition: all .4s ease;
				}
				.drawer-type-2-filter.show {
					opacity: 0.5;
					visibility: visible;
				}
				.drawer-type-2 {
					position: fixed;
					background: ${contentBackground};
					width: 800px;
					z-index: ${zIndex};
					
					opacity: 0;
					visibility: hidden;
					transition: all .4s ease;
					height: 30vh;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
				}
				.drawer-type-2.show {
					height: 85vh;
					opacity: 1;
					visibility: visible;
				}
				.drawer-type-2 .head {
					display: flex;
					justify-content: space-between;
					align-items: center;
					height: 5vh;
					padding: 10px 20px;
					background: #fff;
					border-bottom: 1px solid #DFE3E8;
				}
				.drawer-type-2 .head .navigation {
					display: flex;
					align-items: center;
				}
				.drawer-type-2 .head .navigation span.title {
					font-weight: 600;
					font-style: normal;
					font-size: 16px;
					line-height: 16px;
				}
				.drawer-type-2 .head .action span:hover {
					cursor: pointer;
				}
				.drawer-type-2 .content {
					max-height: 75vh;
					height: ${isContentFlex ? "100%" : "max-content"};
					overflow-y: scroll;
					box-sizing: border-box;
					padding-bottom: ${typeof onSave === "function" ? "50px" : "0"};
				}
				.drawer-type-2 .content::-webkit-scrollbar {
					display: none;
				}
				.drawer-type-2 .flex {
					display: flex;
					flex-direction: column;
					justify-content: center;
				}
				.drawer-type-2 .footer-btn {
					width: 800px;
					padding: 10px;
					background: #fff;
					box-shadow: 0px -3px 10px rgba(145, 158, 171, 0.1);
					position: fixed;
					bottom: 0;
					box-sizing: border-box;
					z-index: ${zIndex + 1};
				}
			`}
		</style>
	</>
);

Type2.propTypes = {
	show: PropTypes.bool.isRequired,
	title: PropTypes.string.isRequired,
	saveTitle: PropTypes.string.isRequired,
	children: PropTypes.any.isRequired,
	zIndex: PropTypes.number.isRequired,
	contentBackground: PropTypes.string.isRequired,
	onHide: PropTypes.func,
	onSave: PropTypes.func,
	isContentFlex: PropTypes.bool,
};

export default Type2;
